<template>
  <Bar :chart-data="chartData" :chart-options="options" dataset-id-key="LC" />
</template>

<script setup>
//import 'chart.js/auto';

import { watch } from 'vue';
import log from 'loglevel';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale
} from 'chart.js';
import { Bar } from 'vue-chartjs';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  TimeScale,
  PointElement,
  LineElement,
  BarElement,
  CategoryScale,
  LinearScale
);

//import 'chartjs-adapter-date-fns';

//import { fr } from 'date-fns/locale';

import { ref, onBeforeMount, onMounted } from 'vue';

const props = defineProps({
  chartData: {
    type: Object,
    required: true
  },
  title: {
    type: String,
    default: ''
  },
  options: {
    type: Object,
    default: () => ({
      transitions: {
        show: {
          animations: {
            x: {
              from: 0
            },
            y: {
              from: 0
            }
          }
        }
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: ''
        }
      },
      legend: {
        display: true,
        labels: {
          //  fontColor: "rgb(255, 99, 132)",
          fontSize: 12,
          fontStyle: 'bold'
        }
      },
      scales: {
        y: {
          stacked: true,
          title: {
            display: true,
            text: "Nb d'heures"
          }
        },
        x: {
          stacked: true,
          min: 0,
          title: {
            display: true,
            text: 'Semaines'
          }
        }
      }
    })
  }
});

let options = ref(props.options);
watch(props.chartData, () => {
  log.info('[Line.vue][watch][props.chartData]', props.chartData);
});

onBeforeMount(() => {
  options.value.plugins.title.text = props.title;
  log.info('[Line.vue][onMounted][data]', props.chartData);
});

onMounted(() => {
  log.info('[Line.vue][onMounted][data]', props.chartData);
});
</script>

<style lang="scss" scoped></style>
