<template>
  <Pie :chart-data="chartData" :chart-options="options" dataset-id-key="PC" />
</template>

<script setup>
//import 'chart.js/auto';

import { watch } from 'vue';
import log from 'loglevel';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Pie } from 'vue-chartjs';

ChartJS.register(Title, Tooltip, ArcElement, Legend);

//import 'chartjs-adapter-date-fns';

//import { fr } from 'date-fns/locale';

import { ref, onBeforeMount, onMounted } from 'vue';

const props = defineProps({
  chartData: {
    type: Object,
    dafault: {
      labels: [],
      datasets: []
    },
    required: true
  },
  title: {
    type: String,
    default: ''
  }
});

let options = ref({
  transitions: {
    show: {
      animations: {
        x: {
          from: 0
        },
        y: {
          from: 0
        }
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    arc: {
      circular: true
    }
  },
  plugins: {
    title: {
      display: true,
      text: props.title
    },
    legend: {
      display: true,
      labels: {
        //  fontColor: "rgb(255, 99, 132)",
        fontSize: 12,
        fontStyle: 'bold'
      }
    }
  }
});

watch(props.chartData, () => {
  log.info('[Pie.vue][watch][props.chartData]', props.chartData);
});

onBeforeMount(() => {
  log.info('[Pie.vue][onMounted][data]', props.chartData);
});

onMounted(() => {
  log.info('[Pie.vue][onMounted][data]', props.chartData);
});
</script>

<style lang="scss" scoped></style>
